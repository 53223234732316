import { DisplayCurrency } from '@core/interfaces/displayCurrency';
import {Environment} from '@interfaces/environments';
import packageInfo from '../../package.json';


export const environment: Environment = {
  production: true,
  hasBidaiondo: true,
  showPartialPayed: false,
  hasMultipleAgencies: true,
  hasReferredUsers: false,
  showSecondsLanguages: false,
  hasTraedores: false,
  hasReservationBoat: false,
  hasBorrowers: false,
  hasSalesRestrictions: false,
  hasPriceWithTax: false,
  hasFlightNumber: false,
  hasReservationProcessing: false,
  hasReservationModification: false,
  hasLinkeao: false,
  hasCredit: false,
  hasPeopleGoTo: false,
  hasAdg: false,
  hasAgencyReservations: false,
  hasPagoEnTransito: false,
  dateRangeForDayByDefault: false,
  showDescriptionInVoucher: false,
  isOkaturs: false,
  apiUrl: 'https://apiexperiencias.havanatursa.com/v1/',
  imageUrl: 'https://cdnturismo.havanatursa.com/',
  securityUrl: 'https://apiseg.havanatursa.com/v1/',
  agencyName: 'Havanatur SA',
  agency: 'havanatur',
  marketType: 'international',
  imgError: '',
  ServiceBusiness: 'Experiencias',
  defaultLanguage: {
    name: 'Español',
    image: 'assets/images/flags/es.png',
    lang: 'es',
  },
  defaultCurrency: 'USD',
  defaultDisplayCurrency: DisplayCurrency.symbolNarrow,
  defaultMarket: {
    lat: 23.110774,
    lng: -82.440937,
  },
  gateway: {
    visa: 'bidaiondo',
    mastercard: 'bidaiondo',
    'american-express': 'bidaiondo',
    jcb: 'bidaiondo',
    dinners: 'bidaiondo',
    iupay: 'bidaiondo',
    maestro: 'bidaiondo',
    virtual: 'bidaiondo',
    electron: 'bidaiondo',
    bizum: 'bidaiondo',
    discover: 'bidaiondo',
    adg: 'adg',
    'transferencia-QR': 'transferencia-QR',
    'in-transit': 'in-transit',
    sber: 'sber'
  },
  versions: {
    app: packageInfo.version,
  },
  domain: 'havanatursa.com',
  gdsServices: [
    {
      icon: 'timeline',
      id: 'dashboard',
      route: 'https://portalventas.havanatursa.com',
      name: 'Dashboard',
      isActive: true
    },
    {
      icon: 'apartment',
      id: 'hotel',
      route: 'https://adminhoteles.havanatursa.com',
      name: 'Hoteles',
      isActive: true
    },
    {
      icon: 'flight',
      id: 'flight',
      name: 'Charter',
      route: 'https://adminvuelos.havanatursa.com/',
      isActive: true
    },
    {
      icon: 'local_play',
      id: 'experiences',
      name: 'Experiencias',
      route: 'https://adminexperiencias.havanatursa.com/',
      isActive: true
    },
    {
      icon: 'local_taxi',
      id: 'car',
      route: 'https://adminautos.havanatursa.com/',
      name: 'Autos',
      isActive: true
    },
    {
      icon: 'house',
      id: 'house',
      name: 'Casas',
      route: 'https://admincasas.havanatursa.com/',
      isActive: false,
    },
    {
      icon: 'airport_shuttle',
      id: 'transfer',
      name: 'Transfer',
      route: 'https://admintransfer.havanatursa.com/',
      isActive: true
    }
  ]
};
